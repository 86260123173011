import * as React from "react";
import Card from "../components/contact/card";
import Form from "../components/contact/form";
import Header from "../components/contact/header";
import Layout from "../components/layout";

const Contact = () => {
  return (
    <Layout pageTitle="Contact Us - Forest Grove Montessori Pre-School">
      <Header />
      <Form />
      <Card />
    </Layout>
  );
};

export default Contact;
