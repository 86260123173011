import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import classNames from "classnames"

const googleCaptcha = "6LfRpQ4gAAAAAL6Sw28zO2LCkC9AzYsJAUAE0Ftq"

const Form = () => {
  const [isValid, setIsValid] = React.useState(false)

  let buttonStyle =
    "inline-flex items-center px-8 py-4 font-medium rounded-full shadow text-white bg-greenDark hover:bg-cyan"

  if (!isValid) {
    buttonStyle += " cursor-not-allowed "
  } else {
    buttonStyle += " cursor-pointer "
  }

  debugger

  return (
    <div className="max-w-screen-xl mx-auto py-12 lg:py-24">
      <div className="px-4">
        <h2 className="text-greenDark text-3xl text-center font-light font-quick tracking-tight">
          Get In Touch!
        </h2>
        <div className="mt-10 lg:mt-14">
          <form
            action="https://getform.io/f/c73ab48d-17b4-406c-8e9e-da6558e041c2"
            method="POST"
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="block text-xs font-quick font-bold text-greenDark"
              >
                YOUR NAME (REQUIRED)
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  className="py-3 px-4 text-sm block w-full rounded-none border border-greenDark focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-xs font-quick font-bold text-greenDark"
              >
                YOUR EMAIL (REQUIRED)
              </label>
              <div className="mt-1">
                <input
                  name="email"
                  type="email"
                  className="py-3 px-4 text-sm block w-full rounded-none border border-greenDark focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="subject"
                className="block text-xs font-quick font-bold text-greenDark"
              >
                SUBJECT
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="subject"
                  className="py-3 px-4 text-sm block w-full rounded-none border border-greenDark focus:outline-none"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-xs font-quick font-bold text-greenDark"
              >
                YOUR MESSAGE
              </label>
              <div className="mt-1">
                <textarea
                  type="text"
                  name="message"
                  rows={10}
                  className="py-3 px-4 text-sm block w-full rounded-none border border-greenDark focus:outline-none"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <ReCAPTCHA
                sitekey={googleCaptcha}
                onChange={() => setIsValid(true)}
                className="mb-2"
              />
              <button
                type="submit"
                disabled={!isValid}
                className={classNames(
                  "inline-flex items-center px-8 py-4 font-medium rounded-full shadow ",
                  { "bg-greenDark hover:bg-cyan text-white": isValid },
                  {
                    "cursor-not-allowed bg-gray-500 hover:bg-gray-700":
                      !isValid,
                  }
                )}
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Form
