import * as React from "react"

const Card = () => {
  return (
    <div className="max-w-screen-xl mx-auto pb-12 lg:pb-24 lg:grid lg:grid-cols-2 px-4">
      <div className="px-16 lg:px-32 py-12 bg-cyan text-dark text-center">
        <h2 className="text-dark text-2xl font-bold font-quick tracking-tight">
          Location
        </h2>
        <p className="mt-3 text-base font-light">
          3 Forest Grove Drive, North York, Ontario M2K-1Z4 Major Intersection
          (South of Finch Avenue, East of Bayview Avenue)
        </p>
      </div>
      <div className="px-16 lg:px-32 py-12 bg-pink text-dark text-center">
        <h2 className="text-2xl font-bold font-quick tracking-tight">
          Hours Of Operation
        </h2>
        <p className="mt-3 text-base font-light">
          Open from 8AM to 6PM, Monday through Friday. We Also offer Extended
          hours (8 to 9 a.m. & 4 to 6 p.m.)
        </p>
      </div>
      <div className="px-16 lg:px-32 py-12 bg-pink text-dark text-center">
        <h2 className="text-2xl font-bold font-quick tracking-tight">
          Hours Of Operation
        </h2>
        <p className="mt-3 text-base font-light">
          Open from 8AM to 6PM, Monday through Friday. We Also offer Extended
          hours (8 to 9 a.m. & 4 to 6 p.m.)
        </p>
      </div>
    </div>
  )
}

export default Card
